import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components';
import { Container } from 'semantic-ui-react';

import NewsDetail from './CatList/NewsDetail';
import PopupSubscribeForm from './PopupSubscribeForm';

const StyledContainer = styled(Container)`
  min-height: 40vh;
  margin-bottom: 40px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: 20px;
  }
`;

export const NewsDetailPageTemplate = ({ post }) => {
  return (
    <div>
      <PopupSubscribeForm />
      <Helmet>
        <title>BestFit</title>
        <link rel="canonical" href="https://best-fit.app/" />
      </Helmet>
      <section>
        <StyledContainer>
          <NewsDetail cat={post} />
        </StyledContainer>
      </section>
    </div>
  )
}


export default NewsDetailPageTemplate;
